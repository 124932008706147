import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  AngularFireAuthGuardModule,
  customClaims,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
export const redirectLoggedInToReservations = () => redirectLoggedInTo(['reservations']);
export const isAdmin = () =>
  pipe(
    customClaims,
    map((claims) => 'role' in claims && claims.role === 'ADMIN'),
    map((admin) => (admin ? true : redirectLoggedInToReservations)),
  );
export const isAdminOrUser = () =>
  pipe(
    customClaims,
    map((claims) => 'role' in claims && (claims.role === 'ADMIN' || claims.role === 'USER')),
    map((hasRole) => (hasRole ? true : redirectUnauthorizedToLogin)),
  );

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/user-login-page/user-login-page.module').then(
        (mod) => mod.UserLoginPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToReservations },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/user-forgot-password-page/user-forgot-password-page.module').then(
        (mod) => mod.UserForgotPasswordPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToReservations },
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/container/container.module').then((mod) => mod.ContainerModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), AngularFireAuthGuardModule],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
